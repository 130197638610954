import React, { useContext, useState, useEffect } from 'react';
import { navigate, Link } from 'gatsby'
import moment from 'moment'
import MaskedInput from 'react-maskedinput'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Context as LeaveContext } from '../components/LeaveContext'
import PrivateRoute from '../components/PrivateRoute'

export default function New_Leave_Request_step4() {
  const { LeaveRequest, stepFour } = useContext(LeaveContext)

  const [state, setState] = useState({
    LastDayOfWork: '',
    ShowTimeOff: false,
    LeaveStart: '',
    LeaveEnd: '',
    ShowLeaveReasonID: '',
    LeaveReasonID: '',
    LeaveReasonList: [],
    ShowIsPregnancyRelated: false,
    IsPregnancyRelated: '',
    ShowExpectedDueDate: false,
    ExpectedDueDate: '',
    ShowChildDOB: false,
    ChildDOB: '',
    ShowIsLeaveBecuaseOfFamily: false,
    IsLeaveBecuaseOfFamily: '',
    ShowLeaveExplanation: false,
    LeaveExplanation: '',
    ShowIsWorkRelatedHealthCondition: false,
    IsWorkRelatedHealthCondition: '',
    ShowWorkRelatedExplanation: false,
    WorkRelatedExplanation: '',
    ShowIsFiledWorkerCompensationClaim: false,
    IsFiledWorkerCompensationClaim: '',
    ShowIsPeriodic_Intermittent_ReducedScheduledLeave: false,
    IsPeriodic_Intermittent_ReducedScheduledLeave: '',
    ShowPeriodic_Intermittent_ReducedScheduledLeave_Explanation: false,
    Periodic_Intermittent_ReducedScheduledLeave_Explanation: '',
    ShowPaidVacation: false,
    IsPaidVacation: '',
    AcknowledgePaidVaction: false,
    Error: {},
  })

  useEffect(() => {
    if (!LeaveRequest) {
      navigate('/Overview/');
    } else {
      setState(prev => ({
        ...prev,
        LastDayOfWork: LeaveRequest.LastDayOfWork ? LeaveRequest.LastDayOfWork : '',
        ShowTimeOff: !!LeaveRequest.LastDayOfWork,
        LeaveStart: LeaveRequest.LeaveStart ? LeaveRequest.LeaveStart : '',
        LeaveEnd: LeaveRequest.LeaveEnd ? LeaveRequest.LeaveEnd : '',
        ShowLeaveReasonID: LeaveRequest.LeaveEnd ? LeaveRequest.LeaveEnd : '',
        LeaveReasonID: '',
        LeaveReasonList: LeaveRequest.LeaveReasonList,
        ShowIsPregnancyRelated: false,
        IsPregnancyRelated: '',
        ShowExpectedDueDate: false,
        ExpectedDueDate: '',
        ShowChildDOB: false,
        ChildDOB: '',
        ShowIsLeaveBecuaseOfFamily: false,
        IsLeaveBecuaseOfFamily: '',
        ShowLeaveExplanation: false,
        LeaveExplanation: '',
        ShowIsWorkRelatedHealthCondition: false,
        IsWorkRelatedHealthCondition: '',
        ShowWorkRelatedExplanation: false,
        WorkRelatedExplanation: '',
        ShowIsFiledWorkerCompensationClaim: false,
        IsFiledWorkerCompensationClaim: '',
        ShowIsPeriodic_Intermittent_ReducedScheduledLeave: false,
        IsPeriodic_Intermittent_ReducedScheduledLeave: '',
        ShowPeriodic_Intermittent_ReducedScheduledLeave_Explanation: false,
        Periodic_Intermittent_ReducedScheduledLeave_Explanation: '',
        ShowPaidVacation: false,
        IsPaidVacation: '',
        AcknowledgePaidVaction: false,
        Error: LeaveRequest.ErrorMessage ? { ErrorMessage: LeaveRequest.ErrorMessage } : {},
      }))
    }
  }, [LeaveRequest])

  const FamilyMemberOptions = ['2', '8', '9']
  const PregnancyRelatedOptions = ['12']

  const onSubmit = (event) => {
    event.preventDefault()
    const Errors = {}
    // Validate Data

    if (!validateLastDayOfWorkDuringLeave()) Errors.LastDayOfWork = 'Last day of work cannot be during or after you start leave'

    if (!validateTimeOff()) {
      Errors.LeaveStart = 'Leave Start cannot be after Leave End'
      Errors.LeaveEnd = 'Leave End cannot be before Leave Start'
    }

    if (!validateLastDayOfWork()) Errors.LastDayOfWork = 'Last day of work is not valid date (MM/DD/YYY)'

    if (!validateLeaveStart()) Errors.LeaveStart = 'Leave start is not valid date (MM/DD/YYY)'

    if (!validateLeaveEnd()) Errors.LeaveEnd = 'Leave end is not valid date (MM/DD/YYY)'

    if (!validateLeaveReasonID()) Errors.LeaveReasonID = 'Please select a leave reason'

    if (state.ShowChildDOB && !validateChildDOB()) Errors.ChildDOB = 'Child DOB is not valid date'

    if (state.ShowIsPregnancyRelated && !validateIsPregnancyRelated()) Errors.IsPregnancyRelated = 'Please select pregnancy related'

    if (state.LeaveReasonID === '3' && !state.ShowIsPregnancyRelated && !validateExpectedDueDate()) Errors.ExpectedDueDate = 'Expected due date is not valid date'

    if (state.ShowIsLeaveBecuaseOfFamily && !validateIsLeaveBecuaseOfFamily()) Errors.IsLeaveBecuaseOfFamily = 'Family Member is not valid'

    if (state.LeaveReasonID === '11' && !validateLeaveExplanation()) Errors.LeaveExplanation = 'Leave explanation is not valid'

    if (state.LeaveReasonID === '1' && !validateIsWorkRelatedHealthCondition()) Errors.IsWorkRelatedHealthCondition = 'Please select Work Related Health Condition'

    // if(state.IsWorkRelatedHealthCondition === "Yes" && state.LeaveReasonID === "1" && !validateWorkRelatedExplanation())
    //   Errors.WorkRelatedExplanation = 'Work Related explanation is not valid'

    if (state.ShowIsFiledWorkerCompensationClaim && !validIsFiledWorkerCompensationClaim()) Errors.IsFiledWorkerCompensationClaim = 'Please select if you have filed a workers compensation claim'

    if (state.LeaveReasonID !== '4' && state.LeaveReasonID !== '11' && !validateIsPeriodic_Intermittent_ReducedScheduledLeave()) Errors.IsPeriodic_Intermittent_ReducedScheduledLeave = 'Please select Periodic, Intermittent or Reduced'

    if (state.LeaveReasonID !== '4' && state.LeaveReasonID !== '11' && state.IsPeriodic_Intermittent_ReducedScheduledLeave === 'Yes' && !validatePeriodic_Intermittent_ReducedScheduledLeave_Explanation()) Errors.Periodic_Intermittent_ReducedScheduledLeave_Explanation = 'Periodic, Intermittent or Reduced explanation is not valid'

    if (!validAcknowledgePaidVaction()) Errors.AcknowledgePaidVaction = 'You must acknowledge the Paid Vacation statement.'
    if (!validateIsPaidVacation()) Errors.IsPaidVacation = 'You must select if you intend to use additional vacation accrual beyond what is required by company policy.'

    // Check for any errors
    if (Object.keys(Errors).length > 0) {
      setState(prev => ({ ...prev, Error: Errors }))
      return false
    }

    // Remove errors before saving to the store
    const leaveRequestData = { ...state }
    delete leaveRequestData.Error

    // Save to the store
    stepFour(leaveRequestData)
    navigate('/New_Leave_Request_step5/');
  }

  // Handle Validation for all fields
  const validateLastDayOfWorkDuringLeave = (LastDay = state.LastDayOfWork, Start = state.LeaveStart) => moment(LastDay, 'MM/DD/YYYY').isBefore(moment(Start, 'MM/DD/YYYY'))
  const validateLastDayOfWork = (value = state.LastDayOfWork) => {
    const TheDate = moment(value, 'MM/DD/YYYY')
    const re = /(^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/([12]\d{3})$)/;
    return re.test(String(value)) && TheDate.isValid() && TheDate.isAfter(moment('1/1/1753', 'MM/DD/YYYY')) && TheDate.isBefore(moment('12/31/9999', 'MM/DD/YYYY'))
  }
  const validateLeaveStart = (value = state.LeaveStart) => {
    const TheDate = moment(value, 'MM/DD/YYYY')
    const re = /(^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/([12]\d{3})$)/;
    return re.test(String(value)) && TheDate.isValid() && TheDate.isAfter(moment('1/1/1753', 'MM/DD/YYYY')) && TheDate.isBefore(moment('12/31/9999', 'MM/DD/YYYY'))
  }
  const validateChildDOB = (value = state.ChildDOB) => {
    const TheDate = moment(value, 'MM/DD/YYYY')
    const re = /(^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/([12]\d{3})$)/;
    return re.test(String(value)) && TheDate.isValid() && TheDate.isAfter(moment('1/1/1753', 'MM/DD/YYYY')) && TheDate.isBefore(moment('12/31/9999', 'MM/DD/YYYY'))
  }
  const validateLeaveEnd = (value = state.LeaveEnd) => {
    const TheDate = moment(value, 'MM/DD/YYYY')
    const re = /(^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/([12]\d{3})$)/;
    return re.test(String(value)) && TheDate.isValid() && TheDate.isAfter(moment('1/1/1753', 'MM/DD/YYYY')) && TheDate.isBefore(moment('12/31/9999', 'MM/DD/YYYY'))
  }
  const validateTimeOff = (value = state.LeaveStart, value2 = state.LeaveEnd) => moment(value, 'MM/DD/YYYY').isBefore(moment(value2, 'MM/DD/YYYY'))
  const validateLeaveReasonID = (value = state.LeaveReasonID) => value !== '-1' && value !== ''
  const validateIsPregnancyRelated = (value = state.IsPregnancyRelated) => value === 'Yes' || value === 'No'
  const validateExpectedDueDate = (value = state.ExpectedDueDate) => {
    const TheDate = moment(value, 'MM/DD/YYYY')
    const re = /(^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/([12]\d{3})$)/;
    return re.test(String(value)) && TheDate.isValid() && TheDate.isAfter(moment('1/1/1753', 'MM/DD/YYYY')) && TheDate.isBefore(moment('12/31/9999', 'MM/DD/YYYY'))
  }
  const validateIsLeaveBecuaseOfFamily = (value = state.IsLeaveBecuaseOfFamily) => value.length > 2
  const validateLeaveExplanation = (value = state.LeaveExplanation) => value.length > 2
  const validateIsWorkRelatedHealthCondition = (value = state.IsWorkRelatedHealthCondition) => value === 'Yes' || value === 'No'
  const validateWorkRelatedExplanation = (value = state.IsWorkRelatedHealthCondition, value2 = state.WorkRelatedExplanation) => value === 'Yes' && value2.length > 2
  const validateIsPeriodic_Intermittent_ReducedScheduledLeave = (value = state.IsPeriodic_Intermittent_ReducedScheduledLeave) => value === 'Yes' || value === 'No'
  const validatePeriodic_Intermittent_ReducedScheduledLeave_Explanation = (value = state.Periodic_Intermittent_ReducedScheduledLeave_Explanation) => value.length > 2
  const validIsFiledWorkerCompensationClaim = (value = state.IsFiledWorkerCompensationClaim) => value === 'Yes' || value === 'No'
  const validAcknowledgePaidVaction = (value = state.AcknowledgePaidVaction) => value
  const validateIsPaidVacation = (value = state.IsPaidVacation) => value === 'Yes' || value === 'No'

  // Handle when to show various sections of the form
  const ShowTimeOffChange = (ShowTimeOff = false) => {
    let Error = { ...state.Error }
    let { LeaveStart } = state
    let { LeaveEnd } = state
    if (!ShowTimeOff) {
      delete Error.LeaveStart
      delete Error.LeaveEnd
      Error = { ...Error }
      LeaveStart = ''
      LeaveEnd = ''
      ShowLeaveReasonID()
      ShowIsPregnancyRelated()
      ShowIsLeaveBecuaseOfFamily()
      ShowIsWorkRelatedHealthCondition()
      ShowLeaveExplanation()
      ShowIsPeriodic_Intermittent_ReducedScheduledLeave()
      ShowPaidVacation()
    }
    setState(prev => ({
      ...prev, Error, ShowTimeOff, LeaveStart, LeaveEnd,
    }))
  }
  const ShowLeaveReasonID = (ShowLeaveReasonID = false) => {
    let Error = { ...state.Error }
    let { LeaveReasonID } = state
    if (!ShowLeaveReasonID) {
      delete Error.LeaveReasonID
      Error = { ...Error }
      LeaveReasonID = ''
      ShowExpectedDueDate()
      ShowIsPregnancyRelated()
      ShowIsLeaveBecuaseOfFamily()
      ShowIsWorkRelatedHealthCondition()
      ShowLeaveExplanation()
      ShowIsPeriodic_Intermittent_ReducedScheduledLeave()
      ShowPaidVacation()
    }
    setState(prev => ({
      ...prev, Error, ShowLeaveReasonID, LeaveReasonID,
    }))
  }
  const ShowIsPregnancyRelated = (ShowIsPregnancyRelated = false) => {
    let Error = { ...state.Error }
    let { IsPregnancyRelated } = state
    if (!ShowIsPregnancyRelated) {
      delete Error.IsPregnancyRelated
      Error = { ...Error }
      IsPregnancyRelated = ''
      ShowIsLeaveBecuaseOfFamily()
      ShowLeaveExplanation()
      ShowExpectedDueDate()
    }
    setState(prev => ({
      ...prev, Error, ShowIsPregnancyRelated, IsPregnancyRelated,
    }))
  }
  const ShowIsFiledWorkerCompensationClaim = (ShowIsFiledWorkerCompensationClaim = false) => {
    let Error = { ...state.Error }
    let { IsFiledWorkerCompensationClaim } = state
    if (!ShowIsFiledWorkerCompensationClaim) {
      delete Error.IsPregnancyRelated
      Error = { ...Error }
      IsFiledWorkerCompensationClaim = ''
      ShowLeaveExplanation()
    }
    setState(prev => ({
      ...prev, Error, ShowIsFiledWorkerCompensationClaim, IsFiledWorkerCompensationClaim,
    }))
  }
  const ShowIsLeaveBecuaseOfFamily = (ShowIsLeaveBecuaseOfFamily = false) => {
    let Error = { ...state.Error }
    let { IsLeaveBecuaseOfFamily } = state
    if (!ShowIsLeaveBecuaseOfFamily) {
      delete Error.IsLeaveBecuaseOfFamily
      Error = { ...Error }
      IsLeaveBecuaseOfFamily = ''
      ShowLeaveExplanation()
    }
    setState(prev => ({
      ...prev, Error, ShowIsLeaveBecuaseOfFamily, IsLeaveBecuaseOfFamily,
    }))
  }
  const ShowIsWorkRelatedHealthCondition = (ShowIsWorkRelatedHealthCondition = false) => {
    let Error = { ...state.Error }
    let { IsWorkRelatedHealthCondition } = state
    let { WorkRelatedExplanation } = state
    if (!ShowIsWorkRelatedHealthCondition) {
      delete Error.IsWorkRelatedHealthCondition
      delete Error.WorkRelatedExplanation
      Error = { ...Error }
      IsWorkRelatedHealthCondition = ''
      WorkRelatedExplanation = ''
      ShowIsFiledWorkerCompensationClaim()
      ShowLeaveExplanation()
    }
    setState(prev => ({
      ...prev, Error, ShowIsWorkRelatedHealthCondition, IsWorkRelatedHealthCondition, WorkRelatedExplanation,
    }))
  }
  const ShowLeaveExplanation = (ShowLeaveExplanation = false) => {
    let Error = { ...state.Error }
    let { LeaveExplanation } = state
    if (!ShowLeaveExplanation) {
      delete Error.LeaveExplanation
      Error = { ...Error }
      LeaveExplanation = ''
      ShowIsPeriodic_Intermittent_ReducedScheduledLeave()
    } else if (state.LeaveReasonID && state.LeaveReasonID !== '11' && state.LeaveReasonID !== '4') {
      ShowIsPeriodic_Intermittent_ReducedScheduledLeave(true)
    } else {
      ShowPaidVacation(true)
    }

    setState(prev => ({
      ...prev, Error, ShowLeaveExplanation, LeaveExplanation,
    }))
  }
  const ShowExpectedDueDate = (ShowExpectedDueDate = false) => {
    let Error = { ...state.Error }
    let { ExpectedDueDate } = state
    if (!ShowExpectedDueDate) {
      delete Error.ExpectedDueDate
      Error = { ...Error }
      ExpectedDueDate = ''
      ShowLeaveExplanation()
    }
    setState(prev => ({
      ...prev, Error, ShowExpectedDueDate, ExpectedDueDate,
    }))
  }
  const ShowChildDOB = (ShowChildDOB = false) => {
    let Error = { ...state.Error }
    let { ChildDOB } = state
    if (!ShowChildDOB) {
      delete Error.ChildDOB
      Error = { ...Error }
      ChildDOB = ''
      ShowLeaveExplanation()
    }
    setState(prev => ({
      ...prev, Error, ShowChildDOB, ChildDOB,
    }))
  }
  const ShowIsPeriodic_Intermittent_ReducedScheduledLeave = (ShowIsPeriodic_Intermittent_ReducedScheduledLeave = false) => {
    let Error = { ...state.Error }
    let { Periodic_Intermittent_ReducedScheduledLeave_Explanation } = state
    let { IsPeriodic_Intermittent_ReducedScheduledLeave } = state
    if (!ShowIsPeriodic_Intermittent_ReducedScheduledLeave) {
      delete Error.Periodic_Intermittent_ReducedScheduledLeave_Explanation
      delete Error.IsPeriodic_Intermittent_ReducedScheduledLeave
      Error = { ...Error }
      Periodic_Intermittent_ReducedScheduledLeave_Explanation = ''
      IsPeriodic_Intermittent_ReducedScheduledLeave = ''
      ShowPaidVacation()
    }
    setState(prev => ({
      ...prev, Error, ShowIsPeriodic_Intermittent_ReducedScheduledLeave, Periodic_Intermittent_ReducedScheduledLeave_Explanation, IsPeriodic_Intermittent_ReducedScheduledLeave,
    }))
  }
  const ShowPaidVacation = (ShowPaidVacation = false) => {
    let Error = { ...state.Error }
    let { IsPaidVacation, AcknowledgePaidVaction } = state
    if (!ShowPaidVacation) {
      delete Error.IsPaidVacation
      delete Error.AcknowledgePaidVaction
      Error = { ...Error }
      IsPaidVacation = ''
      AcknowledgePaidVaction = false
    }
    setState(prev => ({
      ...prev, Error, ShowPaidVacation, IsPaidVacation, AcknowledgePaidVaction,
    }))
  }

  // Hnadles form fields
  const handleLastDayOfWorkChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.LastDayOfWork
    ShowTimeOffChange(e.target.value.length > 0)
    setState(prev => ({ ...prev, LastDayOfWork: e.target.value, Error }))
  }
  const handleLeaveStartChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.LeaveStart
    setState(prev => ({ ...prev, LeaveStart: e.target.value, Error }))
  }
  const handleLeaveEndChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.LeaveEnd
    ShowLeaveReasonID(e.target.value.length > 0)
    setState(prev => ({ ...prev, LeaveEnd: e.target.value, Error }))
  }
  const handleLeaveReasonIDChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.LeaveReasonID

    ShowExpectedDueDate()
    ShowPaidVacation()
    ShowIsPeriodic_Intermittent_ReducedScheduledLeave()
    ShowIsPregnancyRelated()
    ShowIsLeaveBecuaseOfFamily()
    ShowLeaveExplanation()
    ShowChildDOB()
    ShowIsFiledWorkerCompensationClaim()

    setState(prev => ({
      ...prev,
      LeaveReasonID: e.target.value,
      Error,
    }))

    const PregnancyRelated = PregnancyRelatedOptions.indexOf(e.target.value) >= 0
    if (PregnancyRelated) {
      ShowIsPregnancyRelated(true)
      return
    }

    if (e.target.value === '3') {
      ShowExpectedDueDate(true)
      return
    }

    if (e.target.value === '4') {
      ShowChildDOB(true)
      return
    }

    if (FamilyMemberOptions.indexOf(e.target.value) >= 0 || state.IsPregnancyRelated === 'No') {
      ShowIsLeaveBecuaseOfFamily(true)
      return
    }

    if (e.target.value === '1' || e.target.value === '15') {
      ShowIsWorkRelatedHealthCondition(true)
      return
    }

    if (validateLeaveReasonID(e.target.value)) {
      setState(prev => ({ ...prev, Error, ShowLeaveExplanation: true }))
      if (e.target.value !== '11' && e.target.value !== '4') {
        ShowIsPeriodic_Intermittent_ReducedScheduledLeave(true)
      } else {
        ShowPaidVacation(true)
      }
    }
  }
  const handleIsPregnancyRelatedChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.IsPregnancyRelated
    ShowIsLeaveBecuaseOfFamily(FamilyMemberOptions.indexOf(state.LeaveReasonID) >= 0 || e.target.value === 'No')
    ShowExpectedDueDate(e.target.value === 'Yes')
    setState(prev => ({ ...prev, IsPregnancyRelated: e.target.value, Error }))
  }
  const handleExpectedDueDateChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.ExpectedDueDate

    ShowLeaveExplanation(validateExpectedDueDate(e.target.value))

    setState(prev => ({ ...prev, ExpectedDueDate: e.target.value, Error }))
  }
  const handleChildDOBChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.ChildDOB

    ShowLeaveExplanation(validateChildDOB(e.target.value))

    setState(prev => ({ ...prev, ChildDOB: e.target.value, Error }))
  }
  const handleIsLeaveBecuaseOfFamilyChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.IsLeaveBecuaseOfFamily

    ShowLeaveExplanation(validateIsLeaveBecuaseOfFamily(e.target.value))

    setState(prev => ({ ...prev, IsLeaveBecuaseOfFamily: e.target.value, Error }))
  }
  const handleLeaveExplanationChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.LeaveExplanation

    if (state.LeaveReasonID !== '4' && state.LeaveReasonID !== '11') {
      ShowIsPeriodic_Intermittent_ReducedScheduledLeave(true)
    } else {
      ShowPaidVacation(true)
    }

    setState(prev => ({
      ...prev,
      LeaveExplanation: e.target.value,
      Error,
    }))
  }
  const handleIsFiledWorkerCompensationClaimChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.IsFiledWorkerCompensationClaim

    ShowLeaveExplanation(validateIsWorkRelatedHealthCondition(e.target.value))

    setState(prev => ({ ...prev, IsFiledWorkerCompensationClaim: e.target.value, Error }))
  }
  const handleIsWorkRelatedHealthConditionChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.IsWorkRelatedHealthCondition
    let { WorkRelatedExplanation } = state

    ShowIsFiledWorkerCompensationClaim()

    if (e.target.value !== 'Yes') {
      delete Error.WorkRelatedExplanation
      WorkRelatedExplanation = ''
      ShowLeaveExplanation(validateIsWorkRelatedHealthCondition(e.target.value))
    } else {
      ShowIsFiledWorkerCompensationClaim(true)
    }

    setState(prev => ({
      ...prev, IsWorkRelatedHealthCondition: e.target.value, Error, WorkRelatedExplanation,
    }))
  }
  const handleWorkRelatedExplanationChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.WorkRelatedExplanation

    ShowLeaveExplanation(validateWorkRelatedExplanation(state.IsWorkRelatedHealthCondition, e.target.value))

    setState(prev => ({ ...prev, WorkRelatedExplanation: e.target.value, Error }))
  }
  const handleIsPeriodic_Intermittent_ReducedScheduledLeaveChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.IsPeriodic_Intermittent_ReducedScheduledLeave
    let { Periodic_Intermittent_ReducedScheduledLeave_Explanation } = state
    if (e.target.value !== 'Yes') {
      delete Error.Periodic_Intermittent_ReducedScheduledLeave_Explanation
      Periodic_Intermittent_ReducedScheduledLeave_Explanation = ''
    }
    ShowPaidVacation(validateIsPeriodic_Intermittent_ReducedScheduledLeave(e.target.value))
    setState(prev => ({
      ...prev,
      IsPeriodic_Intermittent_ReducedScheduledLeave: e.target.value,
      Error,
      Periodic_Intermittent_ReducedScheduledLeave_Explanation,
    }))
  }
  const handlePeriodic_Intermittent_ReducedScheduledLeave_ExplanationChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.Periodic_Intermittent_ReducedScheduledLeave_Explanation
    setState(prev => ({ ...prev, Periodic_Intermittent_ReducedScheduledLeave_Explanation: e.target.value, Error }))
  }
  const handleIsPaidVacationChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.IsPaidVacation
    setState(prev => ({
      ...prev, IsPaidVacation: e.target.value, Error,
    }))
  }

  const handleAcknowledgePaidVaction = (e) => {
    e.persist()
    // eslint-disable-next-line prefer-const
    let { Error, IsPaidVacation } = state
    delete Error.AcknowledgePaidVaction
    if (!e.target.checked) {
      delete Error.IsPaidVacation
      IsPaidVacation = ''
    }
    setState(prev => ({
      ...prev, AcknowledgePaidVaction: e.target.checked, IsPaidVacation, Error,
    }))
  }

  const getAnimationStyle = delay => ({ animation: `0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) ${0.1 + (delay / 10)}s 1 both fadeIn` })

  return (
    <PrivateRoute>
      <Layout>
        <SEO title="Leave Request Step 4" />
        <div className="content-outline-998 leaveForm">
          <ul className="steps">
            <li
              className="past"
              style={{ marginLeft: -2, paddingLeft: '1.75em', width: '7.75em' }}
            >
              <span>
                <strong>Step 1</strong>
                PERSONAL INFO
              </span>
            </li>
            <li className="past" style={{ width: '7.75em' }}>
              <span>
                <strong>Step 2</strong>MANAGER INFO
              </span>
              <i />
            </li>
            <li className="past" style={{ width: '7em' }}>
              <span>
                <strong>Step 3</strong>
                CONTACT INFO
              </span>
              <i />
            </li>
            <li className="present" style={{ width: '8em' }}>
              <span>
                <strong>Step 4</strong> REQUEST DETAILS
              </span>
              <i />
            </li>
            <li className="future" style={{ width: '11.5em' }}>
              <span>
                <strong>Step 5</strong>
                ACKNOWLEDGMENTS
              </span>
              <i />
            </li>
          </ul>
          <p>&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <div className="questionhdr">
            Please tell us more about what type of leave you require:<br />
          </div>
          <form
            className="LeaveFormEntry"
            onSubmit={onSubmit}
          >
            <div className={state.Error.LastDayOfWork ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(0)}>
              <label>When was/is your last day of work?&nbsp;&nbsp;</label>
              <MaskedInput
                mask="11/11/1111"
                placeholder="MM/DD/YYYY"
                type="text"
                name="ctl00$Holder$Request01$txtLastDayWorked"
                id="ctl00_Holder_Request01_txtLastDayWorked"
                style={{ width: '10em' }}
                value={state.LastDayOfWork}
                onChange={handleLastDayOfWorkChange}
              />
            </div>
            <br className="clear" />
            {state.ShowTimeOff
            && (
            <div className={state.Error.LeaveStart || state.Error.LeaveEnd ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(1)}>
              <label>When do you need time off? From:&nbsp;&nbsp;</label>
              <MaskedInput
                mask="11/11/1111"
                placeholder="MM/DD/YYYY"
                type="text"
                name="ctl00$Holder$Request01$txtLeaveFrom"
                id="ctl00_Holder_Request01_txtLeaveFrom"
                style={{ width: '7.75em' }}
                value={state.LeaveStart}
                onChange={handleLeaveStartChange}
              />
            </div>
            )}
            {state.ShowTimeOff
            && (
            <div className={state.Error.LeaveEnd || state.Error.LeaveEnd ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(2)}>
              To:&nbsp;&nbsp;
              <MaskedInput
                mask="11/11/1111"
                placeholder="MM/DD/YYYY"
                type="text"
                name="ctl00$Holder$Request01$txtLeaveTo"
                id="ctl00_Holder_Request01_txtLeaveTo"
                style={{ width: '7.75em' }}
                value={state.LeaveEnd}
                onChange={handleLeaveEndChange}
              />
              <br className="clear" />
            </div>
            )}

            {state.ShowLeaveReasonID
            && (
            <div className={state.Error.LeaveReasonID ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(0)}>
              <label>What is your reason for requesting leave?&nbsp;&nbsp;</label>
              <br />
              <select
                name="ctl00$Holder$Request01$ddlReason2"
                id="ctl00_Holder_Request01_ddlReason2"
                style={{ width: '31em', marginTop: 10 }}
                value={state.LeaveReasonID}
                onChange={handleLeaveReasonIDChange}
              >
                <option value="-1">Please Select</option>
                {state.LeaveReasonList.filter(request => request.Value !== 12).map(request => <option key={request.Name} value={request.Value}>{request.Name}</option>)}
              </select>
              <br className="clear" />
            </div>
            )}

            {state.ShowIsPregnancyRelated
              && (
              <div className={state.Error.IsPregnancyRelated ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(0)}>
                <label>Is this leave related to your own pregnancy?&nbsp;&nbsp;</label>
                <select
                  id="ctl00_Holder_Request01_IsPregnancy"
                  type="radio"
                  name="ctl00$Holder$Request01$IsPregnancy"
                  style={{ width: '2.25em' }}
                  value={state.IsPregnancyRelated}
                  onChange={handleIsPregnancyRelatedChange}
                >
                  <option value="" />
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
                <br className="clear" />
              </div>
              )
            }
            {state.ShowExpectedDueDate
              && (
              <div className={state.Error.ExpectedDueDate ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(0)}>
                <label>What is your expected due date?&nbsp;&nbsp;</label>
                <MaskedInput
                  mask="11/11/1111"
                  placeholder="MM/DD/YYYY"
                  type="text"
                  name="ctl00$Holder$Request01$txtAnticipatedDate"
                  id="ctl00_Holder_Request01_txtAnticipatedDate"
                  style={{ width: '5.75em' }}
                  value={state.ExpectedDueDate}
                  onChange={handleExpectedDueDateChange}
                />
                <br className="clear" />
              </div>
              )
            }
            {state.ShowChildDOB
              && (
              <div className={state.Error.ChildDOB ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(0)}>
                <label>What is your Childs DOB?&nbsp;&nbsp;</label>
                <MaskedInput
                  mask="11/11/1111"
                  placeholder="MM/DD/YYYY"
                  type="text"
                  name="ctl00$Holder$Request01$txtAnticipatedDate"
                  id="ctl00_Holder_Request01_txtAnticipatedDate"
                  style={{ width: '5.75em' }}
                  value={state.ChildDOB}
                  onChange={handleChildDOBChange}
                />
                <br className="clear" />
              </div>
              )
            }
            {state.ShowIsLeaveBecuaseOfFamily && (
            <div className={state.Error.IsLeaveBecuaseOfFamily ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(0)}>
              <label>
                If you have selected to take leave because of someone other
                than yourself, please specify your family relationship:&nbsp;&nbsp;
              </label>
              <input
                type="text"
                name="ctl00$Holder$Request01$txtRelationship"
                id="ctl00_Holder_Request01_txtRelationship"
                style={{ width: '8em' }}
                value={state.IsLeaveBecuaseOfFamily}
                onChange={handleIsLeaveBecuaseOfFamilyChange}
              />
              <br className="clear" />
            </div>
            )}
            {(state.LeaveReasonID === '1' || state.LeaveReasonID === '15') && (
            <div className={state.Error.IsWorkRelatedHealthCondition ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(0)}>
              {state.LeaveReasonID === '1' ? (
                <label>
                  You selected &quot;My Own Serious Health Condition&quot; is this
                  health condition work related?&nbsp;&nbsp;
                </label>
              ) : (
                <label>
                  You selected &quot;Mental Health&quot; is this
                  mental condition work related?&nbsp;&nbsp;
                </label>
              )}
              <select
                id="ctl00_Holder_Request01_IsHealthConditionWR"
                name="ctl00$Holder$Request01$IsHealthConditionWR"
                style={{ width: '2.25em' }}
                value={state.IsWorkRelatedHealthCondition}
                onChange={handleIsWorkRelatedHealthConditionChange}
              >
                <option value="" />
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              <br className="clear" />
            </div>
            )}
            {state.IsWorkRelatedHealthCondition === 'Yes' && (
            <div className={state.Error.WorkRelatedExplanation ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(0)}>
              {state.LeaveReasonID === '1' ? (
                <label>
                  Please explain how your health condition is work related:&nbsp;&nbsp;
                </label>
              ) : (
                <label>
                  Please explain how your mental condition is work related:&nbsp;&nbsp;
                </label>
              )}
              <br />
              <textarea
                style={{ width: '100%' }}
                name="ctl00$Holder$Request01$txtWorkRelatedExplain"
                rows="5"
                id="ctl00_Holder_Request01_txtWorkRelatedExplain"
                value={state.WorkRelatedExplanation}
                onChange={handleWorkRelatedExplanationChange}
              />
              <br className="clear" />
            </div>
            )}
            {state.ShowIsFiledWorkerCompensationClaim
              && (
              <div className={state.Error.IsFiledWorkerCompensationClaim ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(0)}>
                <label>Did you file a Workers Compensation Claim?&nbsp;&nbsp;</label>
                <select
                  id="ctl00_Holder_Request01_IsPregnancy"
                  type="radio"
                  name="ctl00$Holder$Request01$IsPregnancy"
                  style={{ width: '2.25em' }}
                  value={state.IsFiledWorkerCompensationClaim}
                  onChange={handleIsFiledWorkerCompensationClaimChange}
                >
                  <option value="" />
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
                <br className="clear" />
              </div>
              )
            }
            {state.ShowLeaveExplanation
            && (
            <div className={state.Error.LeaveExplanation ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(0)}>
              <label>
                Please provide an explanation of your need for a leave of
                absence.&nbsp;&nbsp;
              </label>
              <br />
              <textarea
                style={{ width: '100%' }}
                name="ctl00$Holder$Request01$txtConditionDetails"
                rows="5"
                id="ctl00_Holder_Request01_txtConditionDetails"
                value={state.LeaveExplanation}
                onChange={handleLeaveExplanationChange}
              />
              <br className="clear" />
            </div>
            )}
            {state.ShowIsPeriodic_Intermittent_ReducedScheduledLeave
            && (
            <div className={state.Error.IsPeriodic_Intermittent_ReducedScheduledLeave ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(0)}>
              <label>
                Is this periodic, intermittent or reduced schedule leave?&nbsp;&nbsp;
              </label>
              <select
                id="ctl00_Holder_Request01_IsIntermittent"
                name="ctl00$Holder$Request01$IsIntermittent"
                style={{ width: '2.25em' }}
                value={state.IsPeriodic_Intermittent_ReducedScheduledLeave}
                onChange={handleIsPeriodic_Intermittent_ReducedScheduledLeaveChange}
              >
                <option value="" />
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              <br className="clear" />
            </div>
            )}
            {state.IsPeriodic_Intermittent_ReducedScheduledLeave === 'Yes' && (
            <div className={state.Error.Periodic_Intermittent_ReducedScheduledLeave_Explanation ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(0)}>
              <label>
                Please explain how your leave is intermittent, periodic or
                reduced:&nbsp;&nbsp;
              </label>
              <br />
              <textarea
                style={{ width: '100%' }}
                name="ctl00$Holder$Request01$txtReducedLeaveDetails"
                rows="5"
                id="ctl00_Holder_Request01_txtReducedLeaveDetails"
                value={state.Periodic_Intermittent_ReducedScheduledLeave_Explanation}
                onChange={handlePeriodic_Intermittent_ReducedScheduledLeave_ExplanationChange}
              />
              <br className="clear" />
            </div>
            )}
            {state.ShowPaidVacation
            && (
              <div className="formDiv">
                <p>
                  <span className="notation">
                    <strong className="red">Note:&nbsp;</strong>
                    Company policy requires the use of all sick accrual (if any) and up to 40 hours of vacation accrual during a protected leave. If this is an <strong>un-protected</strong> leave, the company requires the use of all sick and all vacation accruals during leave.  State Law may override this requirement.
                  </span>
                </p>
                <br />
                <div className={state.Error.AcknowledgePaidVaction ? 'error formDiv' : 'formDiv'}>
                  I have read and understand this requirement&nbsp;&nbsp;
                  <input
                    type="checkbox"
                    name="checkbox"
                    id="checkbox"
                    value={state.AcknowledgePaidVaction}
                    onChange={handleAcknowledgePaidVaction}
                  />
                </div>
                <br />
              </div>
            )}
            {state.AcknowledgePaidVaction
            && (
              <div className="formDiv">
                <p>
                  Please contact your HR representative or <a href="mailto:leaveofabsence@DecisionOne.com">leaveofabsence@DecisionOne.com</a> for questions.
                </p>
                <br />
                <div className={state.Error.IsPaidVacation ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(0)}>
                  <label>
                     Do you want to use additional vacation accrual beyond what is required by company policy?&nbsp;&nbsp;
                  </label>
                  <select
                    id="ctl00_Holder_Request01_IsPTO"
                    name="ctl00$Holder$Request01$IsPTO"
                    style={{ width: '2.25em' }}
                    value={state.IsPaidVacation}
                    onChange={handleIsPaidVacationChange}
                  >
                    <option value="" />
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                  <br className="clear" />
                </div>
              </div>
            )}

            <div style={{ width: '94.5%', ...getAnimationStyle(0) }} className="formDiv">
              {state.IsPaidVacation === 'Yes' && (
              <div style={getAnimationStyle(0)}>
                <p>If yes, you will be contacted to discuss the options regarding your use of vacation.</p>
                <br className="clear" />
              </div>
              )}
              <br className="clear" />
              <br className="clear" />
              <div className="errorMsgs">
                {Object.keys(state.Error).map(x => <span key={state.Error[x]}>{state.Error[x]}<br /></span>)}
              </div>
              <p className="clear">&nbsp;</p>
              <div id="button" className="floatleft">
                <Link to="/New_Leave_Request_step3/">« Previous Step</Link>
              </div>
              <input
                type="submit"
                id="setp1submit"
                value="Continue &raquo;"
              />
            </div>
          </form>
        </div>
        <div className="whiteMask" />
      </Layout>
    </PrivateRoute>
  )
}
